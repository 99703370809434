;(function () {
  // 保存最开始访问的url，不更新当前参数
  if (!localStorage.getItem('first_view_page')) {
    localStorage.setItem('first_view_page', location.href)
  }

  var parseUrl = (location = '') => {
    var p = location.split('?')[1] || ''
    var keyValue = p.split('&')
    var obj = {}
    for (var i = 0; i < keyValue.length; i++) {
      var item = keyValue[i].split('=')
      var key = item[0]
      var value = item[1]
      obj[key] = value
    }
    return obj
  }

  var utmType = ''
  // 落地页
  var locat = decodeURIComponent(window.location.href)
  var seoList = [
    'www.baidu.',
    'm.baidu.',
    'm.sm.cn',
    'so.com',
    'sogou.com',
    'youdao.com',
    'google.',
    'yahoo.com/',
    'bing.com/',
    'ask.com/',
  ]
  var mdeiaList = [
    'weixin.qq.com',
    'baijiahao.baidu.com',
    'toutiao.com',
    'weibo.com',
    'bilibili.com',
  ]
  var communityList = [
    'pcbeta.com',
    'chinapyg.com',
    'freebuf.com',
    'bbs.pediy.com',
    'anquanke.com',
    'segmentfault.com',
    'csdn.net',
    '52pojie.cn',
    'bbs.kafan.cn',
  ]

  var { referrer = '' } = document
  // 官网内路由跳转、点击首页等进行跳转
  var reg = new RegExp(/threatbook.cn/)
  if (reg.test(referrer)) return
  var param = parseUrl(locat)
  var tmpList = [
    {
      key: 'SEO',
      fn: () =>
        !locat.includes('utm_') && seoList.some((d) => referrer.includes(d)),
    },
    {
      key: 'SEM',
      fn: () => locat.includes('utm_') && !!param && param.utm_medium === 'sem',
    },
    {
      key: 'X社区',
      fn: () =>
        referrer.includes('x.threatbook.com') ||
        (!!param && param.utm_source === 'X社区') ||
        (!!param && param.utm_source === 'X%E7%A4%BE%E5%8C%BA'),
    },
    {
      key: 'S沙箱',
      fn: () => referrer.includes('s.threatbook.com'),
    },
    {
      key: '知乎',
      fn: () =>
        referrer.includes('zhihu.com') ||
        (!!param && param.utm_source === 'zhihu'),
    },
    {
      key: '公众号',
      fn: () =>
        mdeiaList.some((d) => referrer.includes(d)) ||
        (!!param && param.utm_type === 'WeMedia'),
    },
    {
      key: '论坛社区',
      fn: () =>
        communityList.some((d) => referrer.includes(d)) ||
        (!!param && param.utm_type === 'community'),
    },
    {
      key: '外部KOL',
      fn: () => locat.includes('utm_') && param.utm_type === 'KOL',
    },
    {
      key: '品牌广告',
      fn: () => locat.includes('utm_') && param.utm_medium === 'cpt',
    },
    {
      key: '其他引荐',
      fn: () => !!referrer,
    },
    {
      key: '',
      fn: () => !referrer,
    },
  ]
  for (var i = 0; i < tmpList.length; i++) {
    if (tmpList[i].fn()) {
      utmType = tmpList[i].key
      break
    }
  }
  function clearStore() {
    var keys = ['utm_source', 'utm_medium', 'referrer', 'utm_type', 'utmType']

    keys.forEach((key) => {
      localStorage.removeItem(key)
    })
  }
  // 如果有新的utm_source, utm_medium, utm_type, referer 才去重写storage
  // 相当于是使用新的途径进入了页面
  // 否则沿用以前的
  var needKeys = ['utm_source', 'utm_medium', 'utm_type']
  var hasKeys = needKeys.some(function (key) {
    return param[key]
  })
  // 最终存储的才是实际的，不应该按当前的url去判断
  if (hasKeys || referrer) {
    clearStore()
    localStorage.setItem('utmType', utmType)
    localStorage.setItem('utm_source', param.utm_source || '')
    localStorage.setItem('utm_medium', param.utm_medium || '')
    localStorage.setItem('utm_type', param.utm_type || '')
    localStorage.setItem('referrer', referrer || '')
  }
})()

window.sensors = window.sensorsDataAnalytic201505
sensors.init({
  // 测试
  // server_url: 'https://trace.threatbook.cn/sa',
  // 线上
  server_url: 'https://trace.threatbook.cn/sa?project=saas',
  heatmap: { clickmap: 'default', scroll_notice_map: 'default' },
  is_track_single_page: true,
  use_client_time: true,
  send_type: 'beacon',
  preset_properties: {
    latest_landing_page: true,
  },
  // show_log: true,
})

var utm_keyword = (function () {
  var parseUrl = (location = '') => {
    var p = location.split('?')[1] || ''
    var keyValue = p.split('&')
    var obj = {}
    for (var i = 0; i < keyValue.length; i++) {
      var item = keyValue[i].split('=')
      var key = item[0]
      var value = item[1]
      obj[key] = value
    }
    return obj
  }
  var locat = decodeURIComponent(window.location.href)
  var params = parseUrl(locat) || {}
  var plan = params.plan || ''
  var unit = params.unit || ''
  var keyword = params.keyword || ''
  if (!plan && !unit && !keyword) return ''
  return 'plan=' + plan + ',unit=' + unit + ',keyword=' + keyword
})()
sessionStorage.setItem('utm_keyword', utm_keyword)

sensors.registerPage({
  product_line: '公司官网',
  utm_type: localStorage.getItem('utmType') || '直接访问',
  utm_source: localStorage.getItem('utm_source'),
  utm_medium: localStorage.getItem('utm_medium'),
  utm_type_source: localStorage.getItem('utm_type'),
  prev_url: localStorage.getItem('referrer'),
  utm_keyword: utm_keyword,
  first_view_page: localStorage.getItem('first_view_page'),
})
sensors.quick('autoTrack')
window.tb_env = 'production'
